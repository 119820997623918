<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<img class="home-image" src="../assets/layout/images/home.jpg" alt="">
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.home-image {
    max-width: 100%;
}
</style>